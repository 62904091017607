.loader {
  width: 66px;
  height: 66px;
  border: 3px solid #B13D89;
  border-radius: 50%;
  border-right: 3px solid transparent;
  margin: 0 auto 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

.k-post-test {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(181, 240, 196);
  background-image: url(./bg.png);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  overflow: auto;
}

.k-post-test[data-open="true"] {
  opacity: 1;
  pointer-events: auto;
}

.k-post-test__stage {
  margin: auto 0;
  margin-top: 260px;
}

.k-post-test[data-stage="thankyou"] {
  background-image: url(./bg2.png);
}