.k-ab-test {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  pointer-events: none;
}

.k-ab-test[data-open="false"],
.k-ab-test [data-open="false"] {
	opacity: 0;
}

.k-ab-test iframe {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 74.3vw;
  height: 42vw;
}

.k-ab-test iframe[src*="NnMfIzEA5Z4"] {
  width: 58.5vw;
}

.k-ab-test-gap {
  width: 100%;
  height: 100%;
  background-image: url(./bg.png);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}