.terms {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: url(./bg2.png);
	background-size: cover;
}

.terms__inner {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	height: 90%;
	max-width: 790px;
	max-height: 796px;
	display: flex;
	flex-direction: column;
	padding: 40px;
}

.terms__logo {
	text-align: center;
	margin: 0 0 20px;
}

.terms__close {
	position: absolute;
	right: 40px;
	top: 40px;
	width: 10px;
	height: 10px;
	cursor: pointer;
}

.terms__copy {
	position: relative;
	flex: 1;
	overflow: auto;
	text-align: left;
	font-size: 12px;
	color: #383838;
	font-family: Arial, Helvetica, sans-serif;
	padding: 0 0 40px;
	mask: url(./mask.png);
	mask-mode: alpha;
}

.terms__copy h2 {
	font-size: 20px;
	margin: 0 0 4px;
	font-weight: 500;
}

.terms__copy h3 {
	font-size: 16px;
	margin: 25px 0 4px !important;
	font-weight: 600;
}

.terms__copy h4 {
	font-size: 12px;
	margin: 25px 0 4px;
	font-weight: 500;
}

.terms__copy h3 + h4 {
	margin-top: 4px;
}

.terms__copy p {
	margin: 0 0 5px;
}

.terms__btn {
	margin: 50px 0 0;
}