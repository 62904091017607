.mobile-warning {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: url(./bg.png);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
	text-align: center;
}

.mobile-warning h3 {
	font-size: 24px;
}

.mobile-warning__try {
	font-weight: 400;
	font-size: 16px;
}