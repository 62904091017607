.k-processing-cover {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #27C1BA url('./bg.png') center center no-repeat;
  background-size: cover;
}

.k-processing-cover__spinner {
  width: 60px;
  height: 60px;
  border: 3px solid #27C1BA;
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}