.upload-header {
	background: rgba(0,0,0,0.6);
	padding: 35px;
	text-align: center;
	width: 100%;
	position: fixed;
/* 	outline: 6px solid #fff; */
/* 	outline-offset: -12px; */
}

.upload-header h2 {
	font-size: 32px;
	color: #fff;
}

.upload-header h3 {
	color: #d9d4af;
}

.upload-header .progress-ring__wrap .progress-ring__num {
	color: #fff;
}

.upload-header h2 + h3 {
	font-size: 18px;
	font-weight: 300;
	margin: -40px 0 0;
}