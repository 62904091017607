.k-demo-form {
	margin: 0px auto 120px;
}

.k-demo-form__btn-wrap {
	text-align: center;
	margin-top: 40px;
}

.k-demo-form__finish {
  text-align: center;
}

.k-demo-form [data-open] {
	display: none;
}

.k-demo-form [data-open="true"] {
	display: block;
}