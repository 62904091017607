.k-checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.k-checkbox__check {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}

.k-checkbox__icon {
  width: 18px;
  height: 18px;
  background: #fff;
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.k-checkbox__icon img {
  width: 12px;
  height: auto;
  opacity: 0;
}

.k-checkbox__check:checked + .k-checkbox__icon img {
  opacity: 1;
}