.mobile-warning {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	background: url(./bg.png);
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 40px;
	text-align: center;
}

.mobile-warning h3 {
	font-size: 24px;
}

.mobile-warning__try {
	font-weight: 400;
	font-size: 16px;
}

.mobile-warning__download {
	font-weight: 400;
	font-size: 10px;
}

.nochrome_warning_inner {
	width: 100%;
	padding: 0 20px;
	max-width: 480px;
	margin: 0 auto;
	text-align: left;
}

.nochrome_warning_inner a#copy_button {
	width: 90px;
	text-decoration: none;
	text-align: center;
	color: #fff;
	padding: 10px 10px;
	background: #5abeb9;
	float: right;
}

.nochrome_warning_inner a#copy_button:before {
	display: none;
}

#link_url {
	width: calc(100% - 90px);
	float: left;
	font-family: Heebo, sans-serif;
	-webkit-appearance: none;
	border: none;
	border-radius: 0;
	padding: 10px 20px;
	font-size: 1em;
	font-weight: 400;
	opacity: 0.7;
	position: relative;
	top: -2px;
	outline: none !important;
}

.copy_input_wrap {
	overflow: hidden;
}