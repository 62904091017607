.k-screen {
	width: 573px;
	height: 360px;
	border: 2px solid #97B2C0;
}

.k-screen-msg {
	position: fixed;
	right: 0;
	top: 0;
	width: 255px;
	height: 100%;
	overflow: auto;
	padding: 80px 40px 40px 24px;
	background: rgba(255, 255, 255, .4);
  text-align: left;
  font-size: 16px;
}

.k-screen-msg__icon {
  margin: 0 0 10px;
  display: block;
  width: 39px;
}

.k-screen-msg__intro {
  font-size: 23px;
  margin: 0 0 30px;
}

.k-screen-msg__title {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0 -14px;
}