.k-form {
	position: relative;
	text-align: left;
	margin: 0 auto;
	width: 100%;
	max-width: 600px;
}

.k-form input[type=text],
.k-form input[type=email],
.k-form select {
  display: block;
  width: 100%;
  border: none;
  font-size: 18px;
  font-weight: 400;
  color: #383838;
  padding: 1.1em;
  border: none;
  background: rgba(255, 255, 255, .7);
}

.k-form input[type=text]:focus,
.k-form input[type=email]:focus,
.k-form select:focus {
  outline: 2px solid rgba(0, 0, 0, .5);
  outline-offset: -2px;
}

.k-form input[type=checkbox] {
	width: 20px;
	height: 20px;
	border: none;
}

.k-form select {
  appearance: none;
	background: #fff url(../../img/dropdown-arrow.svg) right center no-repeat;
}

.k-form label {
	display: block;
	font-size: 20px;
	font-weight: 400;
	margin: 0 0 10px;
	color: #383838;
}

.k-form-split {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 33px;
}

.k-input-wrap {
	margin: 0 0 30px;
}

label.k-label-check {
	position: relative;
	color: #465363;
	font-size: 16px;
	padding: 0 0 0 40px;
}

label.k-label-check input[type=checkbox] {
	position: absolute;
	left: 0;
	top: 2px;
	margin: 0 20px 0 0;
}

.k-submit-wrap {
	text-align: center;
	margin: 40px 0 0;
}

.k-submit-wrap button {
	margin-bottom: 20px;
}

.k-form fieldset {
  padding: 0;
  margin: 0;
  border: none;
}

.k-form-input-extra {
	margin: 5px 0 0;
}