.k-test-blocked {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #27C1BA url('./bg.png') center center no-repeat;
  background-size: cover;
  text-align: center;
}