.react-autosuggest__container {
	position: relative;
}

.react-autosuggest__suggestions-container {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
  background: #fff;
  max-height: 250px;
  overflow: auto;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
  z-index: 9;
}

.react-autosuggest__suggestions-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
}

.react-autosuggest__suggestion {
	font-size: 18px;
	padding: 15px 35px;
  border-bottom: 1px solid #97B2C0;
  cursor: pointer;
}

.react-autosuggest__suggestion:last-child {
	border-bottom: none;
}

.react-autosuggest__suggestion:hover {
	background: #efefef;
}