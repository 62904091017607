.progress-ring {
	transform-origin: center;
	transform: rotate(-90deg);
}

.progress-ring__wrap {
  position: relative;
}

.progress-ring__circle {
	transition: 0.35s stroke-dashoffset;
}

.progress-ring__num {
  position: absolute;
  left: 50%;
  top: calc(50% - 3px);
  transform: translate(-50%, -50%);
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
  font-family: Jost, sans-serif;
}