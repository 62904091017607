.k-pre-test {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url(./bg.png);
	background-position: center;
	background-size: cover;
}

body.small-screen .k-pre-test .pretest-panel {
	transform: scale(0.65);
}

.k-terms-txt {
  margin: 40px 0 0;
  font-size: 14px;
}

.k-start-agree {
  font-size: 16px;
  font-weight: 400;
}