.btn {
  position: relative;
  background: #27C1BA;
  appearance: none;
  padding: 0 1.1em;
  height: 60px;
  margin: 0;
  border: none;
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.btn:hover {
  background: #27b5af;
}

.btn--purple {
  background: #B13D89;
}

.btn--purple:hover {
  background: #9a3878;
}

.btn--small {
  font-size: 16px;
}

.btn--loading,
.btn--loading:hover {
  background: rgba(39, 193, 186, .4);
  opacity: 0;
}

.btn--loading::before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 15px 0 0;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn svg {
  width: auto;
  height: 16px;
  margin: 0 0 0 30px;
}