.k-cam-wrap {
  position: relative;
  margin: 0 0 25px;
}

.k-cam {
  display: block;
  /* min-width: 560px;
  min-height: 320px; */
  width: 584px;
  height: 440px;
  border: 2px solid #fff;
  margin: 0 auto;
  background: rgba(255, 255, 255, .4);
}

.k-cam-zone {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 265px;
  height: 65%;
  border: 8px solid #fff;
}

.k-cam-msg {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  text-align: center;
  font-size: 22px;
}

.k-cam-msg img {
  margin: 0 0 10px;
}



.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 36px;
  transform: scale(0.6);
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
