body {
  min-height: 100%;
}

.kanjo-container {
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Heebo, sans-serif;
  font-weight: 300;
  color: #465363;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.kanjo-container *,
.kanjo-container *::before,
.kanjo-container *::after {
  box-sizing: border-box;
}


.kanjo-container a {
  position: relative;
  display: inline-block;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
}

.kanjo-container a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 100%;
  height: 1px;
  background: #465363;
}

.kanjo-container h1,
.kanjo-container h2,
.kanjo-container h3,
.kanjo-container h4 {
  font-weight: 300;
  margin: 0 0 40px;
  line-height: 1.3;
}

.kanjo-container h2 {
  font-size: 46px;
}

.kanjo-container h3 {
  font-size: 28px;
}

.kanjo-container h2 + h3 {
  margin-top: -25px;
}
